import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface AuthGuardProps {
	children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
	const { isAuthenticated, isSurveyCompleted, user } = useAuth();

	if (!isAuthenticated || !isSurveyCompleted) {
		if (!isAuthenticated) {
			return <Redirect to="/login" />;
		}

		if (user.role.type !== 'authenticated') {
			return <>{children}</>;
		}

		// if (!user?.user_profile?.mobile_number_verified) {
		// 	return <Redirect to="/mobile-registration" />;
		// }
		// if (!user?.user_profile?.business_entity) {
		// 	return <Redirect to="/account-settings" />;
		// }
		if (!user?.market_readiness?.completed) {
			return <Redirect to="/mrs" />;
		}
	}

	return <>{children}</>;
};

AuthGuard.propTypes = {
	children: PropTypes.node,
};

export default AuthGuard;
