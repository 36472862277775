import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/theme';
import { Link as RouterLink } from 'react-router-dom';
import {
	Box,
	Button,
	IconButton,
	Popover,
	SvgIcon,
	Tooltip,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { Bell as BellIcon } from 'react-feather';
import NotificationDialog from './notificationDialog';

const useStyles = makeStyles((theme: Theme) => ({
	popover: {
		width: 320,
	},
}));

const Notifications: FC = () => {
	const classes = useStyles();
	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);
	const [isFormOpen, setFormOpen] = useState(null);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleFormClose = (): void => {
		setFormOpen(false);
	};

	return (
		<>
			<Tooltip title="Notifications">
				<IconButton color="inherit" ref={ref} onClick={handleOpen}>
					<SvgIcon>
						<BellIcon />
					</SvgIcon>
				</IconButton>
			</Tooltip>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
			>
				<Box p={2}>
					<Typography variant="h5" color="textPrimary">
						Notifications
					</Typography>
				</Box>

				<Box p={1} display="flex" justifyContent="center">
					<Button component={RouterLink} size="small" to="#">
						Mark all as read
					</Button>
				</Box>
			</Popover>
			{isFormOpen && (
				<NotificationDialog
					isOpen={isFormOpen}
					notification={isFormOpen}
					handleClose={handleFormClose}
				/>
			)}
		</>
	);
};

export default Notifications;
