import { gql } from '@apollo/client';

export const CREATE_USER_NOTIFICATION = gql`
	mutation createUserNotification($input: createUserNotificationInput!) {
		createUserNotification(input: $input) {
			userNotification {
				id
				created_at
				users_permissions_user {
					id
					email
				}
				notification {
					id
					title
				}
			}
		}
	}
`;

export const UPDATE_USER_NOTIFICATION = gql`
	mutation updateUserNotification($input: updateUserNotificationInput!) {
		updateUserNotification(input: $input) {
			userNotification {
				id
				created_at
				interested
				users_permissions_user {
					id
					email
				}
				notification {
					id
					title
				}
			}
		}
	}
`;
