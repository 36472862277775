import { gql } from '@apollo/client';

export const GET_PIPELINE_WITH_STAGES = gql`
	query pipelines(
		$sort: String
		$limit: Int
		$start: Int
		$where: JSON
		$pipelineStageSort: String
		$publicationState: PublicationState
	) {
		pipelines(
			sort: $sort
			limit: $limit
			start: $start
			where: $where
			publicationState: $publicationState
		) {
			id
			pipeline
			pipeline_stages(sort: $pipelineStageSort) {
				id
				stage
				weight
				stage_type {
					name
					weight
					description
				}
			}
			users_permissions_role {
				id
				name
				type
			}
		}
	}
`;
