import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
	Box,
	Button,
	FormHelperText,
	TextField,
	makeStyles,
	InputAdornment,
	IconButton,
	CircularProgress,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import { Refresh, Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';
const { REACT_APP_BASEURL } = process.env;

interface JWTLoginProps {
	className?: string;
}

const useStyles = makeStyles(theme => ({
	root: {},
	googleButton: {
		backgroundColor: theme.palette.common.white,
	},
	providerIcon: {
		marginRight: theme.spacing(2),
	},
	divider: {
		flexGrow: 1,
	},
	dividerText: {
		margin: theme.spacing(2),
	},
	btnWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	  },
	  captchaImage: {
		height:"62px",
	  },
}));

const JWTLogin: FC<JWTLoginProps> = ({ className, ...rest }) => {
	const classes = useStyles();
	const { login } = useAuth() as any;
	const isMountedRef = useIsMountedRef();
	const [captcha, setCaptcha] = useState<string>("");
	const [captchaId, setCaptchaId] = useState('');
	const [loading, setLoading] = useState<{ captcha: boolean; data: boolean }>({
		data: false,
		captcha: false,
	});
	const firstMount = useRef(true);
	const getCaptcha = async () => {
		setLoading((prev) => ({ ...prev, captcha: true }));
		try {
			const { data } = await axios.get(`${REACT_APP_BASEURL}re-captchas/get-captcha`);
			setCaptcha(data.image_path);
			setCaptchaId(data.id);
		} catch (err) {
			const message = err.response ? err.response.data.message : err.message;
			enqueueSnackbar(message, {
				variant: 'error',
			});
		} finally {
			setLoading((prev) => ({ ...prev, captcha: false }));
		}
	};

	useEffect(() => {
		if (firstMount.current) {
		  getCaptcha();
	
		  return () => {
			firstMount.current = false;
		  };
		}
	  }, []);
	const { enqueueSnackbar } = useSnackbar();
	return (
		<>
			{/* <>
				<Button
					className={classes.googleButton}
					fullWidth
					onClick={handleGoogleClick}
					size="large"
					variant="contained"
				>
					<img
						alt="Google"
						className={classes.providerIcon}
						src="/static/images/google.svg"
					/>
					Sign On with Google
				</Button>
				<Box alignItems="center" display="flex" mt={2}>
					<Divider className={classes.divider} orientation="horizontal" />
					<Typography
						color="textSecondary"
						variant="body1"
						className={classes.dividerText}
					>
						OR
					</Typography>
					<Divider className={classes.divider} orientation="horizontal" />
				</Box>
			</> */}
			<Formik
				initialValues={{
					email: '',
					password: '',
					captcha_text:'',
					showPassword: false,
					submit: null,
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email('Must be a valid email')
						.max(255)
						.required('Email is required'),
					password: Yup.string()
						.max(255)
						.required('Password is required'),
					captcha_text: Yup.string().required('Captcha is required')
				})}
				onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
					try {
						await login(values.email, values.password,values.captcha_text,captchaId);
						if (isMountedRef.current) {
							setStatus({ success: true });
							setSubmitting(false);
						}
					} catch (err) {
						let error =
						typeof(err?.response?.data?.message) !== 'string' ? err?.response?.data?.message[0]?.messages[0]?.message : (err?.response?.data?.message ||
							'Some Error Occured');
						enqueueSnackbar(error, {
							variant: 'error',
						});
						getCaptcha();
						if (isMountedRef.current) {
							setStatus({ success: false });
							setErrors({ submit: error });
							setSubmitting(false);
						}
					}
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					setFieldValue,
					values,
				}) => (
					<form
					    data-testid ='form'
						noValidate
						onSubmit={handleSubmit}
						className={clsx(classes.root, className)}
						{...rest}
					>
						<TextField
							error={Boolean(touched.email && errors.email)}
							fullWidth
							data-testid = "email"
							helperText={touched.email && errors.email}
							label="Email Address"
							margin="normal"
							name="email"
							onBlur={handleBlur}
							onChange={handleChange}
							type="email"
							value={values.email}
							variant="outlined"
						/>
						<TextField
							error={Boolean(touched.password && errors.password)}
							fullWidth
							data-testid = 'password'
							helperText={touched.password && errors.password}
							label="Password"
							margin="normal"
							name="password"
							onBlur={handleBlur}
							onChange={handleChange}
							type={values.showPassword ? 'text' : 'password'}
							value={values.password}
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											// edge="end"
											
											data-testid = 'passwordButton'
											size="small"
											onClick={() =>
												setFieldValue('showPassword', !values.showPassword)
											}
										>
											{values.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						{errors.submit && (
							<Box mt={3}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box className={classes.btnWrapper}>
							<img
								src={captcha}
								alt="captcha"
								className={classes.captchaImage}
							/>
							<IconButton onClick={getCaptcha}>
								{loading.captcha ? <CircularProgress size={30} /> : <Refresh />}
							</IconButton>
							<Box mb={2}>
								<TextField
								    data-testid = 'captchaId'
									name="captcha_text"
									label="Captcha"
									size="small"
									type="text"
									fullWidth
									value={values.captcha_text}
									onChange={handleChange}
									onBlur={handleBlur}
									required
									error={Boolean(touched.captcha_text && errors.captcha_text)}
									helperText={touched.captcha_text && errors.captcha_text}
								/>
							</Box>
						</Box>
			
						<Box mt={2}>
							<Button
								color="secondary"
								data-testid = 'submitButton'
								disabled={isSubmitting}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
							>
								Log In
							</Button>
						</Box>
						{/* <Box mt={2}>
            <Alert
              severity="info"
            >
              <div>
                Use
                {' '}
                <b>demo@devias.io</b>
                {' '}
                and password
                {' '}
                <b>Password123</b>
              </div>
            </Alert>
          </Box> */}
					</form>
				)}
			</Formik>
		</>
	);
};

JWTLogin.propTypes = {
	className: PropTypes.string,
};

export default JWTLogin;
