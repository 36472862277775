import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface DashboardAdminGuardProps {
	children?: ReactNode;
}

const DashboardAdminGuard: FC<DashboardAdminGuardProps> = ({ children }) => {
	const { isAuthenticated, isSurveyCompleted, user } = useAuth();

	if (!isAuthenticated || !isSurveyCompleted) {
		if (!isAuthenticated) {
			return <Redirect to="/login" />;
		}

		if (user.role.type === 'dashboard_admin') {
			return <>{children}</>;
		}
        else 
        return <Redirect to="/404" />;
	}

	return <>{children}</>;
};

DashboardAdminGuard.propTypes = {
	children: PropTypes.node,
};

export default DashboardAdminGuard;
