import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated , user } = useAuth();

  if (isAuthenticated) {

    if(user?.role?.type === "authenticated"){
      return <Redirect to="/app/home" />;
    }
    else if(user?.role?.type === "dashboard_admin" ){
      return <Redirect to="/app/reports/google-dashboard" />;
    }
    else if(user?.role?.type === "fk_admin"|| user?.role?.type === "operation_head"){
      return <Redirect to="/app/management/reportadmin" />;
    }else if(user?.role?.type === "mentor"){
      return <Redirect to="/app/pipeline/msme-assesment-submissions" />
    }
    else{
      return <Redirect to="/app/pipeline/leads" />;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
