export const getToken = (): string | null => {
	const accessToken = window.localStorage.getItem('accessToken');
	if (accessToken) {
		return accessToken;
	}
	return null;
};

export const snakeToPascal = (str: string) => {
	if (!str || typeof str !== 'string') return '';

	// replace the underscore with a space and split the string into an array of words
	let words = str.replace(/_/g, ' ').split(' ');

	// capitalize the first letter of each word and join them back into a string
	let pascal = words
		.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)} `)
		.join('');

	return pascal;
};
