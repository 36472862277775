import React from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/theme';
import {
	Box,
	Card,
	CardContent,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import Auth0Login from './Auth0Login';
import FirebaseAuthLogin from './FirebaseAuthLogin';
import JWTLogin from './JWTLogin';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100vh',
	},
	banner: {
		backgroundColor: theme.palette.background.paper,
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	bannerChip: {
		marginRight: theme.spacing(2),
	},
	methodIcon: {
		height: 30,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	cardContainer: {
		paddingBottom: 80,
		paddingTop: 80,
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	cardContent: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		minHeight: 400,
	},
	currentMethodIcon: {
		height: 40,
		'& > img': {
			width: 'auto',
			maxHeight: '100%',
		},
	},
}));

const LoginView: FC = () => {
	const classes = useStyles();
	const { method } = useAuth() as any;

	return (
		<Page className={classes.root} title="Login">
			<Container className={classes.cardContainer} maxWidth="sm">
				<Card>
					<CardContent className={classes.cardContent}>
						<Box
							alignItems="center"
							display="flex"
							justifyContent="space-between"
							mb={3}
						>
							<div>
								<Typography color="textPrimary" gutterBottom variant="h2">
									Sign in
								</Typography>
								<Typography variant="body2" color="textSecondary">
									Sign in on the internal platform
								</Typography>
							</div>
						</Box>
						<Box flexGrow={1} mt={1}>
							{method === 'Auth0' && <Auth0Login />}
							{method === 'FirebaseAuth' && <FirebaseAuthLogin />}
							{method === 'JWT' && <JWTLogin />}
						</Box>
					</CardContent>
				</Card>
			</Container>
		</Page>
	);
};

export default LoginView;
