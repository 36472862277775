/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, FC, ReactNode } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import {
	Avatar,
	Box,
	Divider,
	Drawer,
	Hidden,
	Link,
	List,
	ListSubheader,
	makeStyles,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
	Home as HomeIcon,
	Layers as LayersIcon,
	Users as UsersIcon,
	PieChart as PieChartIcon,
	BookOpen as BookOpenIcon,
	Link as LinkIcon,
} from 'react-feather';
import {
	StreetviewOutlined,
	TimelineOutlined,
	PeopleOutline,
	PersonAdd,
	PeopleAlt,
	CheckCircleOutline,
	FindInPageOutlined,
	Pages,
	RestorePage,
	LocalOffer,
	LocalMovies,
	LocalLibrary,
	LocalLibraryRounded,
	LocalLibrarySharp,
} from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { GET_USER_CLUSTERS } from 'src/graphql/clusterUser/query';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { GET_CLUSTERS } from 'src/graphql/cluster/query';
import { GET_PIPELINE_WITH_STAGES } from 'src/graphql/pipeline/query';

interface NavBarProps {
	onMobileClose: () => void;
	openMobile: boolean;
}

interface Item {
	href?: string;
	icon?: ReactNode;
	info?: ReactNode;
	items?: Item[];
	title: string;
}

interface Section {
	items: Item[];
	subheader: string;
}

const cmSections: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Seller Link Approval',
				icon: LinkIcon,
				href: '/app/seller-live-link-approval',
			},
			{
				title: 'QC learning approval',
				icon: LocalLibrarySharp,
				href: '/app/qc-learning-approval',
			},
			{
				title: 'QA Monitoring',
				icon: CheckCircleOutline,
				href: '/app/qa-monitoring',
			},
			{
				title: 'Registered MSMEs',
				icon: UsersIcon,
				href: '/app/registered-msme/users/partial',
			},
		],
	},
];
const adminSections: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Personalized Advisory',
				href: '/app/personalized-advisory/1',
				items: [
					{
						title: 'Reallocation',
						icon: PersonAdd,
						items: [
							{
								title: 'First Allocation',
								href: '/app/personalized-advisory/1',
							},
							{
								title: 'Mentee System',
								href: '/app/personalized-advisory/2',
							},
							{
								title: 'Mentor Manual',
								href: '/app/personalized-advisory/3',
							},
						],
					},
					{
						title: 'Post Session',
						icon: PeopleAlt,
						items: [
							{
								title: 'Post Session',
								href: '/app/personalized-advisory/postSession',
							},
							{
								title: 'Attendance No',
								href: '/app/personalized-advisory/attendanceLead',
							},
							{
								title: 'Feedback Pending',
								href: '/app/personalized-advisory/feedbackLead',
							},
						],
					},
				],
			},
			{
				title: 'Download CSV',
				items: [
					{
						title: 'All Clusters and Rejections',
						href: '/app/pipeline/leads/download_logs',
					}
				],
			},
			{
				title: 'Bulk Upload Logs',
				items: [
					{
						title: 'Lead Logs',
						href: '/app/pipeline/leads/bulkUpload_logs',
					},
					{
						title: 'FKB2C Logs',
						href: '/app/pipeline/leads/FKB2C_Logs',
					},
				],
			},
			{
				title: 'FK status update',
				items: [
					{
						title: 'Upload',
						href: '/app/pipeline/leads/upload_logs',
					},
					{
						title: 'Rejection',
						href: '/app/pipeline/leads/rejection_logs',
					},
					{
						title: 'Backup',
						href: '/app/pipeline/leads/backup_logs',
					},
				],
			},
			{
				title: 'Registered MSMEs',
				icon: UsersIcon,
				href: '/app/registered-msme/users/partial',
			},
		],
	},
	{
		subheader: 'Management',
		items: [
			{
				title: 'Users',
				icon: UsersIcon,
				href: '/app/management/users',
				items: [
					{
						title: 'List Users',
						href: '/app/management/users',
					},
					{
						title: 'Event visitors',
						href: '/app/management/event-users',
					},
					{
						title: 'Deleted Users',
						href: '/app/management/deleted-users',
					},
				],
			},
		],
	},
];
const dashboardAdminSection: Section[] = [
	{
		subheader: 'Reports',
		items: [
			{
				title: 'Dashboard',
				icon: PieChartIcon,
				href: '/app/reports/google-dashboard',
			},
		],
	},
];
const enablerSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			// {
			// 	title: 'Playablo Data',
			// 	icon: BookOpenIcon,
			// 	href: '/app/management/playablodata',
			// },
		],
	},
];
const qcManagerSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Learning QC',
				items: [
					{
						title: 'All Leads',
						href: '/app/pipeline/leads/stage/all',
					},
				],
			},
			{
				title: 'FK QA',
				items: [
					{
						title: 'All Leads',
						href: '/app/pipeline/leads/fkWorkflow/all',
					},
				],
			},
			{
				title: 'Overall dashboard',
				icon: CheckCircleOutline,
				href: '/app/learning-interaction',
			},
		],
	},
];

const telecallerSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Bulk Upload Logs',
				items: [
					{
						title: 'Lead Logs',
						href: '/app/pipeline/leads/bulkUpload_logs',
					},
				],
			},

			{
				title: 'Playablo Data',
				icon: BookOpenIcon,
				href: '/app/management/playablodata',
			},
		],
	},
];
const reportSection: Section[] = [
	{
		subheader: 'management',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/management/reportadmin',
			},
			{
				title: 'Overall Dashboard',
				icon: CheckCircleOutline,
				href: '/app/management/overall',
			},
		],
	},
];
const flipkartSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Bulk Upload Logs',
				items: [
					{
						title: 'Lead Logs',
						href: '/app/pipeline/leads/bulkUpload_logs',
					},
					{
						title: 'FKB2C Logs',
						href: '/app/pipeline/leads/FKB2C_Logs',
					},
				],
			},
		],
	},
];
const msmeUserSections: Section[] = [
	{
		subheader: 'Pages',
		items: [
			{
				title: 'Home',
				href: '/app/home',
				icon: HomeIcon,
			},
		],
	},
];
const mentorSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'MSME Assesment Submissions',
				href: '/app/pipeline/msme-assesment-submissions',
				icon: StreetviewOutlined,
			},
			{
				title: 'Talk to MSME',
				href: '/app/pipeline/talk-to-msme',
				icon: PeopleOutline,
			},
			{
				title: 'My TimeLine',
				href: '/app/pipeline/my-timeline',
				icon: TimelineOutlined,
			},
			{
				title: 'My Mentees',
				href: '/app/pipeline/mentee-change-request',
				icon: PersonAdd,
			},
		],
	},
];

const eventManagerSections: Section[] = [
	{
		subheader: 'Management',
		items: [
			{
				title: 'Event visitors',
				icon: UsersIcon,
				href: '/app/management/event-users',
				
			},
		],
	},
];
// const mentorSection: Section[] = [
// 	{
// 		subheader: 'Pipeline',
// 		items: [
// 			{
// 				title: 'MSME Assesment Submissions',
// 				href: '/app/pipeline/msme-assesment-submissions',
// 				icon: StreetviewOutlined,
// 			},
// 			{
// 				title: 'Talk to MSME',
// 				href: '/app/pipeline/talk-to-msme',
// 				icon: PeopleOutline,
// 			},
// 			{
// 				title: 'My TimeLine',
// 				href: '/app/pipeline/my-timeline',
// 				icon: TimelineOutlined,
// 			},
// 			{
// 				title: 'Mentee Change Request',
// 				href: '/app/pipeline/mentee-change-request',
// 				icon: PersonAdd,
// 			},
// 		],
// 	},
// ];

function renderNavItems({
	items,
	pathname,
	depth = 0,
}: {
	items: Item[];
	pathname: string;
	depth?: number;
}) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
				[],
			)}
		</List>
	);
}

function reduceChildRoutes({
	acc,
	pathname,
	item,
	depth,
}: {
	acc: any[];
	pathname: string;
	item: Item;
	depth: number;
}) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false,
		});

		acc.push(
			<NavItem
				depth={depth}
				icon={item.icon}
				info={item.info}
				key={key}
				open={Boolean(open)}
				title={item.title}
			>
				{renderNavItems({
					depth: depth + 1,
					pathname,
					items: item.items,
				})}
			</NavItem>,
		);
	} else {
		acc.push(
			<NavItem
				depth={depth}
				href={item.href}
				icon={item.icon}
				info={item.info}
				key={key}
				title={item.title}
			/>,
		);
	}

	return acc;
}

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)',
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64,
	},
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();
	const { user } = useAuth();
	const [sections, setSections] = useState<Section[]>(
		user?.role.type === 'admin'
			? adminSections
			: user?.role.type === 'cluster_manager'
			? cmSections
			: user?.role.type === 'enabler'
			? enablerSection
			: user?.role.type === 'mentor'
			? mentorSection
			: user?.role.type === 'telecaller'
			? telecallerSection
			: user?.role.type === 'fk_admin' || user?.role.type === 'operation_head'
			? reportSection
			: user?.role.type === 'dashboard_admin'
			? dashboardAdminSection
			: user?.role.type === 'flipkartb_2_b' ||
			  user?.role.type === 'flipkartb_2_c' ||
			  user?.role.type === 'flipkartb_2_badmin'
			? flipkartSection
			: user?.role?.type === 'qc_manager' || user?.role?.type === 'qc_reviewer'
			? qcManagerSection
			: user?.role?.type === 'event_manager'
			? eventManagerSections 
			: msmeUserSections,
	);
	const [getUserClusters, { data: clusterManagers }] = useLazyQuery(
		GET_USER_CLUSTERS,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	const [getClusters, { data: clusters }] = useLazyQuery(GET_CLUSTERS, {
		fetchPolicy: 'cache-and-network',
	});

	useQuery(GET_PIPELINE_WITH_STAGES, {
		fetchPolicy: 'network-only',
		variables: {
			pipelineStageSort: 'weight:ASC',
			where: {
				users_permissions_role: user?.role?.id,
			},
		},
		skip: !['enabler', 'qc_manager', 'qc_reviewer'].includes(user.role.type),
		onCompleted: data => {
			let updateSections = [...sections];
			let pipelineItems: any = [];
			pipelineItems.push({
				title: 'All',
				href: `/app/pipeline/leads/stage/all`,
			});
			data?.pipelines[0]?.pipeline_stages?.forEach(element => {
				pipelineItems.push({
					title: element?.stage || '-',
					href: `/app/pipeline/leads/stage/${element?.id}`,
				});
			});

			if (['enabler', 'qc_manager', 'qc_reviewer'].includes(user.role.type))
				updateSections[0].items[0].items = pipelineItems;

			if (['qc_manager', 'qc_reviewer'].includes(user?.role?.type)) {
				if (user?.role?.type === 'qc_reviewer')
					updateSections[0].items = updateSections[0].items.filter(
						obj => obj.title !== 'Overall dashboard',
					);
				const pipelineItems2: any = [];
				pipelineItems2.push({
					title: 'All',
					href: `/app/pipeline/leads/stage/allFK`,
				});
				data?.pipelines[1].pipeline_stages?.forEach(elem => {
					pipelineItems2.push({
						title: elem?.stage || '-',
						href: `/app/pipeline/leads/stage/${elem?.id}`,
					});
				});
				updateSections[0].items[1].items = pipelineItems2;
			}

			setSections(updateSections);
		},
	});

	useEffect(() => {
		if (user?.role?.type === 'admin') {
			let updatedSections = [...sections];
			updatedSections[1].items[0].items.push({
				title: 'Create User',
				href: '/app/management/user/create',
			});
			setSections(updatedSections);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user?.role?.type === 'admin') {
			getClusters();
		}
	}, [user, getClusters]);

	// Note : here user cluster mapping is named as cluster manager
	useEffect(() => {
		let forRoles = ['admin', 'telecaller', 'cluster_manager'];
		if (forRoles.includes(user?.role.type)) {
			let updateSections = [...sections];
			let clustersMenuItems: any = [
				{
					title: 'All',
					href: '/app/pipeline/leads',
				},
			];
			clusters?.clusters?.forEach(cluster => {
				if (cluster)
					clustersMenuItems.push({
						title: cluster?.name || '-',
						href: `/app/pipeline/leads/cluster/${cluster?.id}`,
					});
			});

			if (user.role.type === 'admin') {
				updateSections[0].items[0].items = clustersMenuItems;
			}
			setSections(updateSections);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clusters, setSections]);

	useEffect(() => {
		let forRoles = ['admin', 'flipkartb_2_c', 'cluster_manager'];
		if (forRoles.includes(user?.role.type)) {
			let updateSections = [...sections];
			let clustersMenuItems: any = [
				{
					title: 'All',
					href: '/app/pipeline/leads',
				},
			];
			clusters?.clusters?.forEach(cluster => {
				if (cluster)
					clustersMenuItems.push({
						title: cluster?.name || '-',
						href: `/app/pipeline/leads/cluster/${cluster?.id}`,
					});
			});

			if (
				user.role.type === 'admin' ||
				user.role.type === 'cluster_manager' ||
				user.role.type === 'flipkartb_2_c'
			) {
				updateSections[0].items[0].items = clustersMenuItems;
			}
			setSections(updateSections);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clusters, setSections]);

	useEffect(() => {
		let forRoles = ['telecaller', 'cluster_manager'];
		if (forRoles.includes(user?.role?.type)) {
			getUserClusters({
				variables: {
					where: {
						users_permissions_user: user.id,
					},
				},
			});
		}
	}, [user, getUserClusters]);

	// Note : here user cluster mapping is named as cluster manager
	useEffect(() => {
		let forRoles = ['admin', 'telecaller', 'cluster_manager'];
		if (forRoles.includes(user?.role.type)) {
			let updateSections = [...sections];
			let clustersMenuItems: any = [];
			clusterManagers?.clusterManagers.forEach(clusterManager => {
				if (clusterManager?.cluster)
					clustersMenuItems.push({
						title: clusterManager?.cluster?.name || '-',
						href: `/app/pipeline/leads/cluster/${clusterManager?.cluster?.id}`,
					});
			});

			if (
				user?.role?.type === 'admin' ||
				user?.role?.type === 'cluster_manager'
			) {
				updateSections[0].items[0].items = clustersMenuItems;
			}

			if (user.role.type === 'telecaller' || user.role.type === 'enabler')
				updateSections[0].items[0].items = clustersMenuItems;

			setSections(updateSections);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clusterManagers, setSections]);

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box p={2} display="flex" justifyContent="center">
						<RouterLink to="/">
							<Logo />
						</RouterLink>
					</Box>
				</Hidden>
				<Box p={2}>
					<Box display="flex" justifyContent="center">
						<RouterLink to="/app/account">
							<Avatar
								alt={user?.user_profile?.name || ''}
								className={classes.avatar}
								src={user?.user_profile?.image?.url}
							/>
						</RouterLink>
					</Box>
					<Box mt={2} textAlign="center">
						<Link
							component={RouterLink}
							to="/app/account"
							variant="h5"
							color="textPrimary"
							underline="none"
						>
							{user?.user_profile?.name}
						</Link>
						{user?.role?.type === 'authenticated' && (
							<>
								<Typography variant="body2" color="textSecondary">
									Business Entity:{' '}
									<Link component={RouterLink} to="/app/account">
										{user?.user_profile?.business_entity?.name || '-'}
									</Link>
								</Typography>
								<Typography variant="body2" color="textSecondary">
									Primary Place:{' '}
									<Link component={RouterLink} to="/app/account">
										{user?.user_profile?.business_entity?.primary_place || '-'}
									</Link>
								</Typography>
								<Typography variant="body2" color="textSecondary">
									Business Type:{' '}
									<Link component={RouterLink} to="/app/account">
										{user?.user_profile?.business_entity?.business_type?.name ||
											'-'}
									</Link>
								</Typography>
							</>
						)}
					</Box>
				</Box>
				<Divider />
				<Box p={2}>
					{sections.map(section => (
						<List
							key={section.subheader}
							subheader={
								<ListSubheader disableGutters disableSticky>
									{section.subheader}
								</ListSubheader>
							}
						>
							{renderNavItems({
								items: section.items,
								pathname: location.pathname,
							})}
						</List>
					))}
				</Box>
				<Divider />
				<Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
					<Typography variant="h6" color="textPrimary">
						Need Help?
					</Typography>
					<Typography variant="body2" color="textSecondary">
						Powered by Dhwani RIS
					</Typography>
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default NavBar;
