import { gql } from '@apollo/client';

export const GET_USER_CLUSTERS = gql`
	query clusterManagers(
		$sort: String
		$limit: Int
		$start: Int
		$where: JSON
		$publicationState: PublicationState
	) {
		clusterManagers(
			sort: $sort
			limit: $limit
			start: $start
			where: $where
			publicationState: $publicationState
		) {
			id
			status
			assigned_on
			assigned_by {
				id
				email
			}
			users_permissions_user {
				id
				email
			}
			cluster {
				id
				name
				location
			}
		}
	}
`;
