import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
	query userProfiles(
		$sort: String
		$limit: Int
		$start: Int
		$where: JSON
		$publicationState: PublicationState
	) {
		userProfiles(
			sort: $sort
			limit: $limit
			start: $start
			where: $where
			publicationState: $publicationState
		) {
			id
			name
			departmentName
			branchName
			cityName
			gender
			mobileNumber
			employeeId
			website
			L1_completion
			L2_knowledge_status
			L2_orientation_status
			users_permissions_user {
				id
				email
			}
			image{
				id
				url
			}
			mobile_number_verified
			business_entity {
				id
				name
				primary_place
				msme_udhyog_no
				address
				business_type {
					id
					name
				}
				industry_sector {
					id
					name
				}
			}
		}
	}
`;
