import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
	Avatar,
	Box,
	ButtonBase,
	Hidden,
	Menu,
	MenuItem,
	Typography,
	makeStyles,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import axios from 'axios';
import { LOGOUT } from 'src/utils/endpoints.util';

const useStyles = makeStyles(theme => ({
	avatar: {
		height: 32,
		width: 32,
		marginRight: theme.spacing(1),
	},
	popover: {
		width: 200,
	},
}));

const Account: FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const ref = useRef<any>(null);
	const { user, logout } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleLogout = async (): Promise<void> => {
		try {
			handleClose();
			let elem = document.getElementById('freshchat-lib');
			if (elem) {
				elem.parentNode.removeChild(elem);
			}
			await axios.post(LOGOUT, {
				device_id: '',
				logout_location_lat: '',
				logout_location_lng: '',
			});
			await logout();
			let msmeConnect = localStorage.getItem('connectedToMsmeConnect');
			if (msmeConnect === 'Yes') {
				localStorage.removeItem('connectedToMsmeConnect');
				window.open(
					`https://stgmsme-connect.dhwaniris.in/user/auth/logout`,
					'_self',
				);
			} else {
				history.push('/');
				history.go(0);
			}
		} catch (err) {
			console.error(err);
			enqueueSnackbar('Unable to logout', {
				variant: 'error',
			});
		}
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				component={ButtonBase}
				onClick={handleOpen}
				// @ts-ignore
				ref={ref}
			>
				<Avatar
					alt="User"
					className={classes.avatar}
					src={user?.user_profile?.image?.url}
				/>
				<Hidden smDown>
					<Typography variant="h6" color="inherit">
						{user?.name}
					</Typography>
				</Hidden>
			</Box>
			<Menu
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				keepMounted
				PaperProps={{ className: classes.popover }}
				getContentAnchorEl={null}
				anchorEl={ref.current}
				open={isOpen}
			>
				{/* <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem> */}
				<MenuItem component={RouterLink} to="/app/account">
					Account
				</MenuItem>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</>
	);
};

export default Account;
