import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import DashboardAdminGuard from './components/DashboardAdminGuard';

type Routes = {
	exact?: boolean;
	path?: string | string[];
	guard?: any;
	layout?: any;
	component?: any;
	routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
	<Suspense fallback={<LoadingScreen />}>
		<Switch>
			{routes.map((route, i) => {
				const Guard = route.guard || Fragment;
				const Layout = route.layout || Fragment;
				const Component = route.component;

				return (
					<Route
						key={i}
						path={route.path}
						exact={route.exact}
						render={props => (
							<Guard>
								<Layout>
									{route.routes ? (
										renderRoutes(route.routes)
									) : (
										<Component {...props} />
									)}
								</Layout>
							</Guard>
						)}
					/>
				);
			})}
		</Switch>
	</Suspense>
);

const routes: Routes = [
	{
		exact: true,
		path: '/404',
		component: lazy(() => import('src/views/errors/NotFoundView')),
	},
	{
		exact: false,
		path: '/auth/google/callback',
		component: lazy(() => import('src/components/HandleGoogleLogin')),
	},
	{
		exact: true,
		path: '/auth/thirdpartylogout',
		component: lazy(() => import('src/components/HandleThirdPartyLogout')),
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/login',
		component: lazy(() => import('src/views/auth/LoginView')),
	},
	{
		exact: true,
		path: '/login-unprotected',
		component: lazy(() => import('src/views/auth/LoginView')),
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/register',
		component: lazy(() => import('src/views/auth/RegisterView')),
	},
	{
		exact: true,
		path: '/register-unprotected',
		component: lazy(() => import('src/views/auth/RegisterView')),
	},
	// {
	// 	exact: true,
	// 	path: '/forgot-password',
	// 	component: lazy(() => import('src/views/auth/RegisterView')),
	// },
	{
		exact: true,
		path: '/email/verify/:email/:ref',
		component: lazy(() => import('src/views/auth/OtpVerifyView')),
	},
	{
		exact: true,
		path: '/mobile-registration/:city?',
		component: lazy(() => import('src/views/registration/MobileRegistration/index')),
	},
	{
		exact: true,
		path: '/registration-form/',
		component: lazy(() => import('src/views/registration/Registraionform/index')),
	},
	{
		exact: true,
		path: '/registration-success',
		component: lazy(() => import('src/views/registration/dialogBox/CommonAgreeDialog')),
	},
	{
		exact: true,
		path: '/mobile/verify/:mobile/:ref',
		component: lazy(() => import('src/views/auth/OtpVerifyView')),
	},
	// {
	// 	exact: true,
	// 	path: '/password-reset/:resetPasswordToken',
	// 	component: lazy(() => import('src/views/auth/PasswordResetView')),
	// },
	// {
	// 	exact: true,
	// 	path: '/account-settings',
	// 	component: lazy(() => import('src/views/userProfile')),
	// },
	{
		exact: true,
		path: '/mrs',
		component: lazy(() => import('src/views/marketRedinessSurvey/index')),
	},
	{
		exact: true,
		path: '/appredirectoweb/:jwt/:assessment_type/:assessment_attempt_id',
		component: lazy(() => import('src/components/HandleAssessmentRedirect')),
	},
	{
		exact: true,
		guard: AuthGuard,
		path: '/app/ka',
		component: lazy(() => import('src/views/assessments/knowledge/create')),
	},
	{
		exact: true,
		guard: AuthGuard,
		path: '/app/oa',
		component: lazy(() => import('src/views/assessments/orientation/create')),
	},
	{
		path: '/app',
		guard: AuthGuard,
		layout: DashboardLayout,
		routes: [
			{
				exact: true,
				path: '/app/account',
				component: lazy(() => import('src/views/account/AccountView')),
			},
			{
				exact: true,
				path: '/app/tracker',
				component: lazy(() => import('src/views/dailyTracker')),
			},

			{
				exact: true,
				path: '/app/home',
				component: lazy(() => import('src/views/homepage')),
			},

			{
				exact: true,
				path: '/app/user-live-sessions',
				component: lazy(() =>
					import('src/views/notification/MsmeLiveSessionsNotificationList'),
				),
			},
			{
				exact: true,
				path: '/app/calendar',
				component: lazy(() => import('src/views/calendar/CalendarView')),
			},
			{
				exact: true,
				path: '/app/pipeline/lead_assignments',
				component: lazy(() => import('src/views/leadAssignment')),
			},
			{
				exact: true,
				path: '/app/pipeline/logs',
				component: lazy(() => import('src/views/leadActivityLogs')),
			},
			{
				exact: true,
				path: '/app/pipeline/reminders',
				component: lazy(() => import('src/views/reminders')),
			},
			{
				exact: true,
				path: '/app/pipeline/downloadreport',
				component: lazy(() => import('src/views/downloadReport')),
			},
			{
				exact: true,
				path: '/app/pipeline/lead',
				component: lazy(() => import('src/views/leadReportView')),
			},
			{
				exact: true,
				path: '/app/leads/users',
				component: lazy(() => import('src/views/customer/CustomerListView')),
			},
			{
				exact: true,
				path: '/app/management/users',
				component: lazy(() => import('src/views/users/list')),
			},
			{
				exact: true,
				path: '/app/management/event-users',
				component: lazy(() => import('src/views/users/event-users')),
			},
			{
				exact: true,
				path: '/app/management/deleted-users',
				component: lazy(() => import('src/views/users/deleted-users')),
			},
			{
				exact: true,
				path: '/app/registered-msme/users/partial',
				component: lazy(() => import('src/views/partial/index')),
			},
			{
				exact: true,
				path: '/app/management/user/create',
				component: lazy(() => import('src/views/customer/create')),
			},
			{
				exact: true,
				path: '/app/management/user/create/:id',
				component: lazy(() => import('src/views/customer/create')),
			},

			{
				exact: true,
				path: '/app/management/users/:userId',
				component: lazy(() => import('src/views/customer/CustomerDetailsView')),
			},
			{
				exact: true,
				path: '/app/management/users/:userId/edit',
				component: lazy(() => import('src/views/customer/CustomerEditView')),
			},
			{
				exact: true,
				path: '/app/management/triggers',
				component: lazy(() => import('src/views/triggers/list')),
			},
			{
				exact: true,
				path: '/app/management/notifications',
				component: lazy(() =>
					import('src/views/notification/notificationListView'),
				),
			},
			{
				exact: true,
				path: '/app/management/notifications/create',
				component: lazy(() =>
					import('src/views/notification/NotificationCreateView'),
				),
			},
			{
				exact: true,
				path: '/app/management/notifications/edit/:notificationId',
				component: lazy(() =>
					import('src/views/notification/NotificationEditView'),
				),
			},

			{
				exact: true,
				path: '/app/management/live-sessions',
				component: lazy(() =>
					import('src/views/liveSession/LiveSessionListView'),
				),
			},
			{
				exact: true,
				path: '/app/management/live-sessions/create/:type',
				component: lazy(() =>
					import('src/views/liveSession/LiveSessionCreateView'),
				),
			},
			{
				exact: true,
				path: '/app/management/live-sessions/edit/:type/:liveSessionId',
				component: lazy(() =>
					import('src/views/liveSession/LiveSessionEditView'),
				),
			},
			{
				exact: true,
				path: '/app/management/live-sessions/:type',
				component: lazy(() =>
					import('src/views/liveSession/LiveSessionListView'),
				),
			},
			{
				exact: true,
				path: '/app/management/events/create',
				component: lazy(() => import('src/views/events/EventCreateView')),
			},
			{
				exact: true,
				path: '/app/management/events/edit/:eventId',
				component: lazy(() => import('src/views/events/EventEditView')),
			},
			{
				exact: true,
				path: '/app/management/events',
				component: lazy(() => import('src/views/events/EventListView')),
			},
			{
				exact: true,
				path: '/app/management/clusters',
				component: lazy(() => import('src/views/cluster/ClusterListView')),
			},
			{
				exact: true,
				path: '/app/management/clusters/create',
				component: lazy(() => import('src/views/cluster/ClusterCreateView')),
			},
			{
				exact: true,
				path: '/app/management/clusters/edit/:clusterId',
				component: lazy(() => import('src/views/cluster/ClusterEditView')),
			},
			{
				exact: true,
				path: '/app/management/clusters/:clusterId',
				component: lazy(() => import('src/views/cluster/ClusterDetailView')),
			},
			{
				exact: true,
				path: '/app/management',
				component: () => <Redirect to="/app/management/users" />,
			},
			{
				exact: true,
				path: '/app/management/playablodata',
				component: lazy(() => import('src/views/playablodata/index')),
			},
			{
				exact: true,
				path: '/app/management/reportadmin',
				component: lazy(() => import('src/views/fkadmin/index')),
			},
			{
				exact: true,
				path: '/app/management/overall',
				component: lazy(() => import('src/views/leads/LeadsListView/FkOverAll')),
			},
			{
				exact: true,
				path: '/app/pipeline',
				component: () => <Redirect to="/app/pipeline/leads" />,
			},
			{
				exact: true,
				path: '/app/pipeline/leads',
				component: lazy(() => import('src/views/leads/LeadsListView')),
			},
			{
				exact: true,
				path: '/app/personalized-advisory/:screen',
				component: lazy(() => import('src/views/personalizedAdvisory')),
			},
			{
				exact: true,
				path: '/app/personalized-advisory/sessionDetails/:screen/:id',
				component: lazy(() =>
					import('src/views/personalizedAdvisory/SessionDetails'),
				),
			},
			{
				exact: true,
				path: '/app/personalized-advisory/mentorList/:screen/:id',
				component: lazy(() =>
					import('src/views/personalizedAdvisory/MentorList'),
				),
			},
			{
				exact: true,
				path: '/app/pipeline/dynamicReport',
				component: lazy(() => import('src/views/dynamicReport')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/e-form',
				component: lazy(() =>
					import('src/views/leads/LeadsListView/EnablerForm'),
				),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/download_logs',
				component: lazy(() => import('src/views/leads/download_logs')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/bulkUpload_logs',
				component: lazy(() => import('src/views/leads/BulkUpload_Logs')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/FKB2C_Logs',
				component: lazy(() => import('src/views/leads/FKB2CLogs')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/upload_logs',
				component: lazy(() => import('src/views/leads/rejection_backup_logs')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/rejection_logs',
				component: lazy(() => import('src/views/leads/rejection_backup_logs')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/backup_logs',
				component: lazy(() => import('src/views/leads/rejection_backup_logs')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/create',
				component: lazy(() => import('src/views/leads/LeadsCreateView')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/:type/:typeId',
				component: lazy(() => import('src/views/leads/LeadsListView')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/:leadId',
				component: lazy(() => import('src/views/leads/LeadsDetailsView')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/edit/:leadId',
				component: lazy(() => import('src/views/leads/LeadsCRUDDialogView')),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/assignment/create/:leadId',
				component: lazy(() =>
					import('src/views/leads/Assignment/AssignmentCreateView'),
				),
			},
			{
				exact: true,
				path: '/app/pipeline/leads/assignment/edit/:leadAssignmentId',
				component: lazy(() =>
					import('src/views/leads/Assignment/AssignmentEditView'),
				),
			},
			{
				exact: true,
				path: '/app/pipeline/leads-activity/create/:leadId',
				component: lazy(() =>
					import('src/views/leads/LeadsListView/Activity/ActivityCreateView'),
				),
			},
			{
				exact: true,
				path: '/app/pipeline/leads-activity/:leadId',
				component: lazy(() =>
					import('src/views/leads/LeadsListView/Activity/ActivityListView'),
				),
			},
			{
				exact: true,
				guard: DashboardAdminGuard,
				path: '/app/reports/google-dashboard',
				component: lazy(() => import('src/views/reports/GoogleDashboard')),
			},
			{
				exact: true,
				path: '/app/seller-live-link-approval',
				component: lazy(() => import('src/views/sellerLiveLink')),
			},
			{
				exact: true,
				path: '/app/qc-learning-approval',
				component: lazy(() => import('src/views/chQlLearningApproval')),
			},
			{
				exact: true,
				path: '/app/qa-monitoring',
				component: lazy(() => import('src/views/qmAndCmInteractions')),
			},
			{
				exact: true,
				path: '/app/learning-interaction',
				component: lazy(() => import('src/views/qmAndCmInteractions')),
			},
			{
				exact: true,
				path: '/app/pipeline/msme-assesment-submissions',
				component: lazy(() => import('src/views/l3/msmeAssesmentSubmisstions')),
			},
			{
				exact: true,
				path: '/app/pipeline/talk-to-msme',
				component: lazy(() => import('src/views/l3/talkToMsme')),
			},
			{
				exact: true,
				path: '/app/pipeline/talk-to-msme-feedback',
				component: lazy(() => import('src/views/l3/talkToMsme/Feedback')),
			},
			{
				exact: true,
				path: '/app/pipeline/reminder-note/:id',
				component: lazy(() => import('src/views/l3/talkToMsme/ReminderNote')),
			},
			{
				exact: true,
				path: '/app/pipeline/my-timeline',
				component: lazy(() => import('src/views/l3/myTimeLine')),
			},
			{
				exact: true,
				path: '/app/pipeline/mentee-change-request',
				component: lazy(() => import('src/views/l3/menteeChangeRequest')),
			},
			{
				exact: true,
				path: '/app',
				component: () => <Redirect to="/app/account" />,
			},
			{
				component: () => <Redirect to="/404" />,
			},
		],
	},
	// {
	// 	path: '/docs',
	// 	layout: DocsLayout,
	// 	routes: [
	// 		{
	// 			exact: true,
	// 			path: '/docs',
	// 			component: () => <Redirect to="/docs/welcome" />,
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/welcome',
	// 			component: lazy(() => import('src/views/docs/WelcomeView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/getting-started',
	// 			component: lazy(() => import('src/views/docs/GettingStartedView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/environment-variables',
	// 			component: lazy(() =>
	// 				import('src/views/docs/EnvironmentVariablesView'),
	// 			),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/deployment',
	// 			component: lazy(() => import('src/views/docs/DeploymentView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/api-calls',
	// 			component: lazy(() => import('src/views/docs/APICallsView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/analytics',
	// 			component: lazy(() => import('src/views/docs/AnalyticsView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/authentication',
	// 			component: lazy(() => import('src/views/docs/AuthenticationView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/routing',
	// 			component: lazy(() => import('src/views/docs/RoutingView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/settings',
	// 			component: lazy(() => import('src/views/docs/SettingsView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/state-management',
	// 			component: lazy(() => import('src/views/docs/StateManagementView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/theming',
	// 			component: lazy(() => import('src/views/docs/ThemingView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/support',
	// 			component: lazy(() => import('src/views/docs/SupportView')),
	// 		},
	// 		{
	// 			exact: true,
	// 			path: '/docs/changelog',
	// 			component: lazy(() => import('src/views/docs/ChangelogView')),
	// 		},
	// 		{
	// 			component: () => <Redirect to="/404" />,
	// 		},
	// 	],
	// },
	{
		path: '*',
		layout: MainLayout,
		routes: [
			{
				exact: true,
				path: '/',
				component: HomeView,
			},
			// {
			// 	exact: true,
			// 	path: '/pricing',
			// 	component: lazy(() => import('src/views/pricing/PricingView')),
			// },
			{
				component: () => <Redirect to="/404" />,
			},
		],
	},
];

export default routes;
