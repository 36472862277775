import React from 'react';
import type { FC } from 'react';
import {
	Box,
	Button,
	Typography,
	makeStyles,
	Dialog,
	Card,
	Grid,
	CardMedia,
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import { Plus as PlusIcon , Video as VideoIcon  } from 'react-feather';
import { rest } from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_NOTIFICATION } from 'src/graphql/user_notifications/mutations';
import { useSnackbar } from 'notistack';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	image: {
		height: 200,
		backgroundColor: theme.palette.background.dark,
	},
	badge: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginTop: 10,
		marginRight: 5,
	},
	popover: {
		width: 320,
		padding: theme.spacing(2),
	},
	date: {
		marginLeft: 'auto',
		flexShrink: 0,
	},
	media: {
		height: 250,
		minWidth: 550
	  },
	  placeholder: {
		height: 250,
		minWidth: 600,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	  },
	  insertDriveFileIcon: {
		height: theme.spacing(6),
		width: theme.spacing(6),
		fontSize: theme.spacing(6)
	  },
}));

const NotificationDialog: FC<{
	isOpen: boolean;
	handleClose: () => void;
	notification: any;
}> = ({ isOpen, handleClose, notification }) => {
	const classes = useStyles();

	const [updateUserNotification] = useMutation(UPDATE_USER_NOTIFICATION);
	const { enqueueSnackbar } = useSnackbar();

	const handleInterested = async (
		userNotificationId: string,
		interested: boolean,
	) => {
		try {
			await updateUserNotification({
				variables: {
					input: {
						where: {
							id: userNotificationId,
						},
						data: {
							interested: interested,
						},
					},
				},
			});
			handleClose();
			enqueueSnackbar('Feedback Added', {
				variant: 'success',
			});
		} catch (err) {
			enqueueSnackbar(err?.response?.data?.message || 'Something went wrong', {
				variant: 'error',
			});
		}
	};

	return (
		<Dialog onClose={handleClose} open={isOpen} >
			<Card {...rest}>
				<Box p={3}>
				{notification?.notification?.icon?.url ? (
						<CardMedia
						className={classes.media}
						image={notification?.notification?.icon?.url}
						/>
					) : (
						<div className={classes.placeholder}>
						<VideoIcon className={classes.insertDriveFileIcon} />
						</div>
					)}
					<Box display="flex" alignItems="center" mt={2}>
						<Box>
							<Typography variant="subtitle1" color="textPrimary">
								{notification?.notification?.title}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box pb={2} px={3}>
					<Typography color="textSecondary" variant="body2">
						{notification?.notification?.description}
					</Typography>
				</Box>

				<Box py={2} px={3}>
					<Grid
						alignItems="center"
						container
						justify="space-between"
						spacing={3}
					>
						<Grid item>
							{notification?.notification?.type === 'REDIRECT' && (
								<Typography variant="body2" color="textSecondary">
									<Button
										endIcon={<PlusIcon />}
										onClick={event => {
											window.open(notification?.notification?.redirect_link || '', '_blank');
										}}
									>
										Join
									</Button>
								</Typography>
							)}
							{notification?.notification?.type === 'INTEREST' &&
								notification?.interested === null && (
									<Box mt={1}>
										<Box px={2}>
										<Typography variant="button" component="p">
											Interested ?
											<br />
										</Typography>
										</Box>
											<Button
												size="small"
												color="primary"
												onClick={() => handleInterested(notification.id, true)}
											>
												Yes
											</Button>
											<Button
												size="small"
												color="primary"
												onClick={() => handleInterested(notification.id, false)}
											>
												No
											</Button>
									
									</Box>
								)}
						</Grid>
						<Grid item justify="flex-end">
							<Box p={1} >
								<Typography className={classes.date} variant="caption">
									{notification?.notification?.published_at
										? moment(notification?.notification?.published_at).fromNow()
										: '-'}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Card>
		</Dialog>
	);
};

export default React.memo(NotificationDialog);
