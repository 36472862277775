import React from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/theme';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	Container,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core';
import LoginView from 'src/views/auth/LoginView';
import GuestGuard from 'src/components/GuestGuard';

interface HeroProps {
	className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		paddingTop: 0,
		paddingBottom: 0,
		[theme.breakpoints.down('md')]: {
			paddingTop: 60,
			paddingBottom: 60,
		},
	},
	technologyIcon: {
		height: 40,
		objectFit: 'contain',
		alignSelf: 'flex-start',
		marginTop: '0.3rem',
		// margin: theme.spacing(1),
	},
	image: {
		perspectiveOrigin: 'left center',
		transformStyle: 'preserve-3d',
		perspective: 1500,
		'& > img': {
			maxWidth: '90%',
			height: 'auto',
			transform: 'rotateY(-35deg) rotateX(15deg)',
			backfaceVisibility: 'hidden',
			boxShadow: theme.shadows[16],
		},
	},
	shape: {
		position: 'absolute',
		top: 0,
		left: 0,
		'& > img': {
			maxWidth: '90%',
			height: 'auto',
		},
	},
}));

const Hero: FC<HeroProps> = ({ className, ...rest }) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)} {...rest}>
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					<Grid item xs={12} md={5}>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							height="100%"
						>
							<Typography variant="overline" color="secondary">
								Welcome
							</Typography>
							<Typography variant="h1" color="textPrimary">
								Walmart Vriddhi
							</Typography>
							<Box mt={3}>
								<Typography variant="body1" color="textSecondary">
									Walmart Vriddhi is digitally enabled and cohort-based. It
									gives you access to a two-year programme of tailored training
									and expert one-on-one advice, as well as a network of peers
									and mentors in your local community. Training will cover all
									aspects of business management to empower entrepreneurs,
									including women business owners, in your region for lasting
									success.
								</Typography>
							</Box>
							<Box mt={3}>
								<Grid container spacing={3}>
									<Grid item>
										<Typography variant="h1" color="secondary">
											22,000+
										</Typography>
										<Typography variant="overline" color="textSecondary">
											MSMEs
										</Typography>
									</Grid>

									{/* <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      80+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Lorem Ipsum
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      300+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Lorem Ipsum
                    </Typography>
                  </Grid> */}
								</Grid>
							</Box>
							{/* <Box mt={3}>
                <img
                  alt="Javascript"
                  className={classes.technologyIcon}
                  src="/static/images/javascript.svg"
                />
                <img
                  alt="Typescript"
                  className={classes.technologyIcon}
                  src="/static/images/typescript.svg"
                />
              </Box> */}
							<Typography variant="caption" color="textSecondary">
								Powered By Dhwani RIS
							</Typography>
							<img
								alt="Logo"
								className={classes.technologyIcon}
								src="/static/images/DhwaniLogo.png"
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={7}>
						<Box position="relative">
							<GuestGuard>
								<LoginView />
							</GuestGuard>
							{/* <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/dark-light.png"
                />
              </div> */}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

Hero.propTypes = {
	className: PropTypes.string,
};

export default Hero;
